<template>
  <div>
    <div class="bg-banner" />
    <div id="login-box">
      <div class="login-banner">
        <div class="logo" />
        <img src="https://qn.huat.xyz/mac/20230616204934.png" class="banner" />
      </div>
      <el-form
        ref="loginFromRef"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        autocomplete="on"
      >
        <div class="title-container">
          <h3 class="title">欢迎来到 {{ title }} ! 👋🏻</h3>
        </div>
        <div>
          <el-form-item prop="mobile">
            <el-input
              v-model="loginForm.mobile"
              placeholder="请输入手机号"
              text
              tabindex="1"
              autocomplete="on"
            >
              <template #prefix>
                <span class="svg-container">
                  <svg-icon icon="user" />
                </span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="请输入密码"
              tabindex="2"
              autocomplete="on"
              show-password
              @keyup.enter="handleLogin"
            >
              <template #prefix>
                <span class="svg-container">
                  <svg-icon icon="password" />
                </span>
              </template>
            </el-input>
          </el-form-item>
        </div>
        <!-- <div class="flex-bar">
          <el-checkbox v-model="loginForm.remember"> 记住我 </el-checkbox>
          <el-link
            type="primary"
            :underline="false"
            @click="formType = 'reset'"
          >
            忘记密码了?
          </el-link>
        </div> -->
        <el-button
          :loading="loading"
          type="primary"
          size="large"
          style="width: 100%"
          @click.prevent="handleLogin"
        >
          登录
        </el-button>
        <div class="sub-link">
          <span class="text">还没有帐号?</span>
          <el-link
            type="primary"
            :underline="false"
            @click="formType = 'register'"
          >
            创建新帐号
          </el-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
// 导入组件之后无需注册可直接使用
import { ref } from 'vue'
import { validatePassword, validateMobile } from './rules'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { watchSwitchLang } from '@/utils/i18n'
import { getLoginInfo } from '@/utils/utils'

// 数据源
const loginForm = ref({
  mobile: '',
  password: ''
})
// 验证规则
const loginRules = ref({
  mobile: [
    {
      required: true,
      trigger: 'blur',
      validator: validateMobile()
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword()
    }
  ]
})

// 处理密码框文本显示状态
const passwordType = ref('password')
// const onChangePwdType = () => {
//   if (passwordType.value === 'password') {
//     passwordType.value = 'text'
//   } else {
//     passwordType.value = 'password'
//   }
// }

// 登录动作处理
const loading = ref(false)
const loginFromRef = ref(null)
const store = useStore()
const router = useRouter()
const handleLogin = () => {
  loginFromRef.value.validate((valid) => {
    if (!valid) return

    loading.value = true
    store
      .dispatch('user/login', loginForm.value)
      .then(() => {
        loading.value = false
        // 登录后操作
        router.push('/')
      })
      .catch((err) => {
        console.log('handleLogin', err)
        loading.value = false
      })
  })
}

watchSwitchLang(() => {
  loginFromRef.value.validate()
})

function getLocalLoginInfo() {
  const loginInfo = getLoginInfo()
  if (loginInfo) {
    loginForm.value.mobile = loginInfo.mobile
    loginForm.value.password = loginInfo.password
  }
}

getLocalLoginInfo()
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';

[data-mode='mobile'] {
  #login-box {
    position: relative;
    width: 100%;
    height: 100%;
    top: inherit;
    left: inherit;
    transform: translateX(0) translateY(0);
    flex-direction: column;
    justify-content: start;
    border-radius: 0;
    box-shadow: none;

    .login-banner {
      width: 100%;
      padding: 20px 0;

      .banner {
        position: relative;
        right: inherit;
        width: 100%;
        max-width: 375px;
        margin: 0 auto;
        display: inherit;
        top: inherit;
        transform: translateY(0);
      }
    }

    .login-form {
      width: 100%;
      min-height: auto;
      padding: 30px;
    }
  }

  .copyright {
    position: relative;
    bottom: 0;
    padding-bottom: 10px;
  }
}

:deep(input[type='password']::-ms-reveal) {
  display: none;
}

.bg-banner {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    var(--el-fill-color-lighter),
    var(--el-bg-color-page)
  );
}

#login-box {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--el-bg-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--el-box-shadow);

  .login-banner {
    position: relative;
    width: 450px;
    background-color: var(--el-fill-color-light);
    overflow: hidden;

    .banner {
      width: 100%;

      @include position-center(y);
    }

    .logo {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      background: url('https://qn.huat.xyz/mac/20230608224216.png') no-repeat;
      background-size: contain;
      box-shadow: var(--el-box-shadow-light);
    }
  }

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
    width: 500px;
    padding: 50px;
    overflow: hidden;

    .title-container {
      position: relative;

      .title {
        font-size: 1.3em;
        color: var(--el-text-color-primary);
        margin: 0 auto 30px;
        font-weight: bold;
      }
    }
  }

  .el-form-item {
    margin-bottom: 24px;

    :deep(.el-input) {
      height: 48px;
      line-height: inherit;
      width: 100%;

      input {
        height: 48px;
      }

      .el-input__prefix,
      .el-input__suffix {
        display: flex;
        align-items: center;
      }

      .el-input__prefix {
        left: 10px;
      }

      .el-input__suffix {
        right: 10px;
      }
    }
  }

  .flex-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .sub-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
    color: var(--el-text-color-secondary);

    .text {
      margin-right: 10px;
    }
  }
}

.copyright {
  position: absolute;
  bottom: 30px;
  width: 100%;
  margin: 0;
}
</style>
