import i18n from '@/i18n'
import { phoneNumReg } from '@/utils/utils'

export const validatePassword = () => {
  return (rule, value, callback) => {
    if (value.length < 6) {
      callback(new Error(i18n.global.t('msg.login.passwordRule')))
      // callback(new Error('msg.login.passwordRule'))
    } else {
      callback()
    }
  }
}

export const validateMobile = () => {
  return (rule, value, callback) => {
    if (!phoneNumReg.test(value)) {
      callback(new Error('请输入正确手机号'))
      // callback(new Error('msg.login.passwordRule'))
    } else {
      callback()
    }
  }
}
